<style lang="scss" scoped>
@use "assets/scss/default.scss";
</style>

<template>
  <div class="h-screen flex flex-col">
    <!-- Header fixé en haut -->
    <Header class="flex-shrink-0 mt-3 ml-3 mr-3" />


    <!-- Navbar sur la gauche avec largeur fixe et sans agrandissement -->
    <Navbar
      :navLinks="navLinks"
      class="flex-shrink-0 ml-3 absolute cconainter bottom-0 py-4"
    />

    <!-- Conteneur principal en flex pour navbar et main -->
    <div id="wrapper" class="flex flex-1 min-h-0 absolute p-4 overflow-auto">

      <!-- Main à droite, scrollable en cas de dépassement -->
      <main class="flex-1 min-h-0 h-full overflow-visible flex flex-col">
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
const navLinks = [
  { url: "/printers", name: "Dashboard", icon: "isax isax-element-4" },
  { url: "/printers/productions", name: "Productions", icon: "isax isax-d-cube-scan" },
  { url: "/printers/transactions", name: "Transactions", icon: "isax isax-convert-card" },
  { url: "/printers/stocks", name: "Stocks", icon: "isax isax-box" }
];
</script>

<script>
import HeaderPrinters from "~/components/headerPrinters.vue";

export default {
  name: "PrintersLayout",
  components: { HeaderPrinters },
  mounted() {
    const { $events } = useNuxtApp();
    // Loader
    $events.on("loading", ([state, text, fullHeight]) => {
      this.loadingText = text || "loader.loading";
      this.loading = state;
      this.fullHeight = fullHeight;

      // If loading add overflow hidden
      if (state) document.body.classList.add("overflow-hidden");
      else document.body.classList.remove("overflow-hidden");
      // Move the scroll to the top
      window.scrollTo(0, 0);
    });
  }
};
</script>
